<template>
	<edit-template class="box" @confirm="submit" @cancel="
          $confirm('取消编辑?', '提示', { type: 'warning' })
            .then(res => $router.back())
            .catch(() => {})
        " :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <el-form :model="form" :rules="rules" ref="form" class="form">
      <div class="title">考试基础信息</div>
      <el-form-item label="考试名称" prop="name">
        <el-input placeholder="请输入" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="考试类型" prop="type_id">
        <el-select :popper-append-to-body="false" v-model="form.type_id" @change="typeChange">
          <el-option v-for="(item, index) in typeList" :label="item.name" :key="item.id + index" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <div class="title">考试适用范围</div>
      <el-form-item label="适用校区" prop="school_ids">
        <el-select :disabled="!form.type_id" :popper-append-to-body="false" v-model="form.school_ids" multiple="" @change="clearClasses">
          <el-option v-for="(item, index) in schoolList" :label="item.val" :key="item.key + index" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试年级" prop="grade_ids">
        <el-select :popper-append-to-body="false" v-model="form.grade_ids" multiple="" @change="clearClasses">
          <el-option v-for="(item, index) in gradeList" :label="item.val" :key="item.key + index" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级类型" prop="class_type">
        <el-select :popper-append-to-body="false" v-model="form.class_type" @change="clearClasses">
          <el-option v-for="(item, index) in classTypeList" :label="item.val" :key="item.key + index" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择班级" prop="class_ids">
        <el-button @click="onClickSelectClass">选择班级</el-button>
        <div>
          <span v-for="(grade_class, index) in classSelected" :key="'class' + index">
            <el-tag v-for="(item, _index) in grade_class" :key="'_class' + index + _index" style="margin-right: 15rem" closable="" disable-transitions="" @close="handleTagClose(index, _index)">
              {{ JSON.parse(item).class_name }}
            </el-tag>
          </span>
        </div>
      </el-form-item>
      <div class="title">考试过程安排</div>
      <t-big-data-select :options="subjectList" :config="{
          value: 'id',
          label: 'subject_name',
          children: 'child'
        }">
        <div>
          <div class="subject" v-for="(item, index) in form.courses" :key="index">
            <div class="item_box t-bg-color-info-0">
              <div class="item">
                <span>考试科目</span>
                <el-form-item :prop="'courses.' + index + '.subject_id'" :rules="[{ required: true, message: '请选择考试科目', trigger: 'blur' }]">
                  <t-big-data-select-item v-model="item.subject_id" placeholder="请选择" :disabled="item.id != null"></t-big-data-select-item>
                </el-form-item>
              </div>
              <div class="item">
                <span>开始时间</span>
                <el-form-item :prop="'courses.' + index + '.start_at'" :rules="[{ required: true, message: '请选择开始时间', trigger: 'blur' }]">
                  <el-date-picker v-model="item.start_at" type="datetime" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" placeholder="选择日期时间"> </el-date-picker>
                </el-form-item>
              </div>
              <div class="item">
                <span>结束时间</span>
                <el-form-item :prop="'courses.' + index + '.end_at'" :rules="[{ required: true, message: '请选择结束时间', trigger: 'blur' }]">
                  <el-date-picker v-model="item.end_at" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" placeholder="选择日期时间"> </el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="item btns">
              <div class="btn del-btn" v-show="form.courses.length > 1" @click.prevent="onEventHandle(0, index)"><i class="el-icon-minus"></i></div>
              <div class="btn add-btn" v-if="form.courses.length - 1 === index" @click="onEventHandle(1, index)"><i class="el-icon-plus"></i></div>
            </div>
          </div>
        </div>
      </t-big-data-select>
    </el-form>
    <el-dialog :visible.sync="classVisible" title="选择班级" width="80%" @close="CloseClassSelectedDialog">
      <div v-if="classListTemp.length > 0">
        <div v-for="(item, index) in classListTemp" class="classes" :key="index">
          <el-checkbox style="margin-bottom: 15rem" v-model="classSelectedAllTemp[index]" @change="changeClassSelectedAll($event, index)">{{ item.name }}</el-checkbox>
          <el-checkbox-group v-model="classSelectedTemp[index]" @change="changeClassSelected($event, index)">
            <el-checkbox style="margin-bottom: 15rem" v-for="(_item, _index) in item.data" :label="_item" :key="'_grade' + _index">{{ JSON.parse(_item).class_name }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div v-else="">
        <t-result></t-result>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="classVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmSelectedClass">确 定</el-button>
      </span>
    </el-dialog>
    
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      form: {
        name: null,
        type_id: null,
        school_ids: [],
        grade_ids: [],
        class_type: null,
        class_ids: [],
        courses: [{}]
      },
      //选择班级模态框
      classVisible: false,
      rules: {
        name: [
          { required: true, message: '请输入考试名称' },
          { max: 50, message: '考试名称最多50字', trigger: 'blur' }
        ],
        type_id: [{ required: true, message: '请选择考试类型' }],
        school_ids: [{ required: true, message: '请选择适用校区' }],
        grade_ids: [{ required: true, message: '请选择考试年级' }],
        class_type: [{ required: true, message: '请选择班级类型' }],
        class_ids: [{ required: true, message: '请选择班级' }]
      },
      // 校区列表
      schoolList: [],
      // 考试类型列表
      typeList: [],
      // 班级类型列表
      classTypeList: [],
      // 年级列表
      gradeList: [],
      // 班级临时列表
      classListTemp: [],
      // 班级列表
      classList: [],
      // 班级全选状态列表
      classSelectedAll: [],
      // 班级已选中列表
      classSelected: [],
      // 班级临时全选状态列表
      classSelectedAllTemp: [],
      // 班级临时已选中列表
      classSelectedTemp: [],
      // 科目列表
      subjectList: [],
      // 关闭dialog的状态
      dialogIsConfirm: false,
      // 数据id
      id: null,
      initFinish: false
    }
  },
  watch: {
    'form.type_id'() {
      let flag = this.checkForm()
      if (flag) this.getClassData()
    },
    'form.class_type'() {
      let flag = this.checkForm()
      if (flag) this.getClassData()
    },
    'form.school_ids'() {
      let flag = this.checkForm()
      if (flag) this.getClassData()
    },
    'form.grade_ids'() {
      let flag = this.checkForm()
      if (flag) this.getClassData()
    }
  },
  methods: {
    clearClasses() {
      this.classSelected = this.classSelected.map(_ => [])
      this.classSelectedTemp = this.classSelectedTemp.map(_ => [])
      this.form.class_ids = []
      this.classSelectedAll = this.classSelectedAll.map(res => false)
      this.classSelectedAllTemp = this.classSelectedAllTemp.map(res => false)
    },
    /**提交数据*/
    submit() {
      let formKeys = ['name', 'type_id', 'school_ids', 'grade_ids', 'class_type', 'class_ids', 'courses', 'version']
      this.$refs.form
        .validate()
        .then(res => {
          let params = {}
          formKeys.forEach(item => {
            params[item] = this.form[item]
          })
          // params.courses = temp;
          this.$_axios.put('/exam/' + this.id, params).then(res => {
            let { error } = res.data
            this.$message.success(error.errorUserMsg)
            this.$router.back()
          })
        })
        .catch(err => this.$message.warning('请输入完整的考试信息'))
    },
    /**检查form表单中的一些数据是否为空*/
    checkForm() {
      let keys = ['type_id', 'class_type', 'school_ids', 'grade_ids']
      for (let i of keys) {
        if (!this.form[i] || !this.form[i].length) return false
      }
      return true
    },
    /**初始化*/
    init() {
      // 获取考试类型
      this.$_axios.get('/exam/type?return_type=array').then(res => {
        let { data } = res.data
        this.typeList = data
      })
      // 获取年级列表
      this.$_axios.get('/exam/grade').then(res => {
        let { data } = res.data
        this.gradeList = this.$tools.jsonToArray(data)
      })
      // 获取班级类型
      this.$_axios.get('/exam/class-type').then(res => {
        let { data } = res.data
        this.classTypeList = this.$tools.jsonToArray(data)
      })
      // 获取考试科目
      this.$_axios.get('/exam/subject').then(res => {
        let { data } = res.data
        this.subjectList = data
      })
      // 获取考试初始数据
      this.$_axios.get('/exam/' + this.id).then(res => {
        let { data } = res.data
        data.school_ids = this.$tools.objectNumberToggle(data.school_ids, 1)
        data.type_id += ''
        data.class_type += ''
        data.courses.forEach(item => {
          item.subject_id = [item.subject_pid + '', item.subject_id + '']
          delete item.subject_pid
        })
        this.form = data
        setTimeout(() => {
          this.initFinish = true
          this.getClassData()
        }, 20)

        this.typeChange(data.type_id)
      })
    },
    /**获取班级数据*/
    getClassData() {
      if (!this.initFinish) return
      let flag = true
      if (!flag) return
      this.$_axios
        .post('/exam/classes', {
          type: this.form.class_type,
          school: this.form.school_ids,
          grade: this.form.grade_ids
        })
        .then(res => {
          let { data } = res.data
          this.classSelectedTemp = data.map(_ => [])
          this.classSelected = data.map(_ => [])
          this.classSelectedAllTemp = data.map(_ => false)
          this.classSelectedAll = data.map(_ => false)
          this.classList = data
          let class_ids = this.form.class_ids
          this.classListTemp = data.map((res, i) => {
            this.classSelectedTemp[i] = this.classSelectedTemp[i] || []
            let data = res.data
            res.data = data.map(item => {
              let id = item.id
              if (class_ids.indexOf(id) !== -1) {
                this.classSelectedTemp[i].push(JSON.stringify(item))
              }
              return JSON.stringify(item)
            })
            this.classSelectedAllTemp[i] = this.classList[i].data.length === this.classSelectedTemp[i].length
            this.classSelectedAll = this.classSelectedAllTemp.slice(0)
            return res
          })
          /*for (let i in data){
          this.classSelectedTemp[i]  = this.classSelectedTemp[i]||[];
          let item = data[i];
          for (let j in item.data){
            let children = item.data[j];
            let id = children.id;
            if (class_ids.indexOf(id) !== -1){
              this.classSelectedTemp[i].push(children);
            }
          }
          this.classSelectedAllTemp[i] = this.classList[i].data.length === this.classSelectedTemp[i].length;
          this.classSelectedAll = this.classSelectedAllTemp.slice(0);
        }*/
          this.classSelected = JSON.parse(JSON.stringify(this.classSelectedTemp))
        })
    },
    /**当选择班级按钮被点击*/
    onClickSelectClass() {
      let flag = true
      this.$refs.form.validateField(['type_id', 'class_type', 'school_ids', 'grade_ids'], res => {
        flag = flag ? !res : flag
      })
      if (flag) {
        this.classVisible = true
        this.dialogIsConfirm = false
      }
    },
    /**当选择该年级班级全选被更改*/
    changeClassSelectedAll(e, index) {
      this.classSelectedTemp[index] = e ? this.classList[index].data.map(res => res) : []
    },
    /**当选择班级*/
    changeClassSelected(e, index) {
      this.classSelectedAllTemp[index] = this.classSelectedTemp[index].length === this.classList[index].data.length
      this.$forceUpdate()
    },
    /**确认选中*/
    confirmSelectedClass() {
      this.classSelected = JSON.parse(JSON.stringify(this.classSelectedTemp))
      this.classSelectedAll = JSON.parse(JSON.stringify(this.classSelectedAllTemp))
      this.dialogIsConfirm = true
      this.mapSelectedToFrom()
      this.$refs.form.validateField('class_ids')
      this.classVisible = false
    },
    /**当关闭dialog时的回调*/
    CloseClassSelectedDialog() {
      if (this.dialogIsConfirm) {
        this.classSelected = JSON.parse(JSON.stringify(this.classSelectedTemp))
        this.classSelectedAll = JSON.parse(JSON.stringify(this.classSelectedAllTemp))
      } else {
        this.classSelectedTemp = JSON.parse(JSON.stringify(this.classSelected))
        this.classSelectedAllTemp = JSON.parse(JSON.stringify(this.classSelectedAll))
      }
      this.$forceUpdate()
    },
    /**移除tag标签*/
    handleTagClose(i1, i2) {
      let select = JSON.parse(JSON.stringify(this.classSelected))
      let selectTemp = JSON.parse(JSON.stringify(this.classSelectedTemp))
      select[i1].splice(i2, 1)
      selectTemp[i1].splice(i2, 1)
      this.classSelected = select
      this.classSelectedTemp = selectTemp
      this.classSelectedAll[i1] = false
      this.classSelectedAllTemp[i1] = false
      this.$forceUpdate()
      this.mapSelectedToFrom()
    },
    /**将选中数据映射到form中*/
    mapSelectedToFrom() {
      let selected = this.classSelected
      let temp = []
      selected.forEach(grade => {
        grade.forEach(item => {
          temp.push(JSON.parse(item).id)
        })
      })
      this.form.class_ids = temp
    },
    /**考试类型被更改*/
    typeChange(e) {
      this.$_axios
        .get('/exam/school', {
          params: {
            id: e
          }
        })
        .then(res => {
          let { data } = res.data
          this.schoolList = this.$tools.jsonToArray(data)
        })
    },
    onEventHandle(type, index) {
      if (type) {
        this.form.courses.push({
          subject_id: null,
          start_at: null,
          end_at: null
        })
      } else {
        let data = this.form.courses[index]
        if (this.form.courses.length > 1) {
          if (data.id != null) {
            this.$_axios
              .post('/site/exam-course-del-check', {
                exam_course_id: data.id
              })
              .then(res => {
                this.form.courses.splice(index, 1)
              })
          } else {
            this.form.courses.splice(index, 1)
          }
        }
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.box {
  .form {
    font-size: 14rem;
    margin: 0 0 20rem 50rem;
    ::v-deep .el-form-item__error {
      margin-left: 80rem;
    }
    .title {
      font-weight: bold;
      font-size: 16rem;
      padding-top: 35rem;
      margin-bottom: 35rem;
    }
    .el-form-item {
      margin-left: 50rem;
    }
    .subject {
      display: flex;
      align-items: center;
      //padding: 15rem 0rem;
      //justify-content: space-around;
      margin-bottom: 15rem;
      ::v-deep .el-form-item__error {
        margin-left: 0;
      }
      .item_box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15rem 10rem;
        flex: 1;
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10rem;
        > span {
          margin-right: 15rem;
        }

        .btn {
          border-radius: 50%;
          width: 30rem;
          height: 30rem;
          text-align: center;
          line-height: 30rem;
          cursor: pointer;
          margin-left: 15rem;
          border: 1rem solid #666666;
        }
      }

      .item.btns {
        width: 100rem;
        justify-content: flex-start;
        text-align: left;
        .btn {
          display: inline-block;
        }
      }

      .el-form-item {
        margin: 0;
      }
    }
    @media only screen and (max-width: 1399rem) {
      .subject {
        .item {
          display: block;
          text-align: center;
          > span {
            display: block;
            margin-right: 0;
            margin-bottom: 15rem;
          }
          ::v-deep .el-input {
            width: 180rem !important;
          }
        }
      }
    }
  }
}
</style>
